<template lang="pug">
CanvasModal.genie-modal(
  :isShow="isShow"
  @closeOverlay="closeGenieAnimator"
)
  div.genie-header
    svg.animation-icon(id="animateSvg" width="22" height="17" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg")
      ellipse(cx="5.25379" cy="5.76291" rx="5.25379" ry="5.46408" fill="white")
      ellipse(opacity="0.2" cx="11.6366" cy="5.76291" rx="5.25379" ry="5.46408" fill="white")

    h4.header-title One Click Animation
    button.btn-play-genie(
      type='button'
      @click="playGenie"
      :class="{ 'is-playing': isGeniePlaying }"
      :title="playTitle"
    )
      i.icon.icon-play(v-if="!isGeniePlaying")
      i.icon.icon-pause(v-if="isGeniePlaying")
      span.icon-white-background

  .genie__container
    .genie__column
      ul.genie__list
        li.genie__list-item(
          v-for="(transition, index) in animationList[0].list"
          v-if="!transition.onlyText"
          :key="transition.value"
        )
          AnimationTabItem.genie-animation__button(
            color-class="is-green"
            :transition="transition"
            :selected-transition='tempValue',
            @change-animation="selectAnimation"
          )

    .genie__column
      .genie__wrapper
        BasePreloader.genie__preloader(
          v-if="isLoading"
        )
        CanvasScene(
          :elements="genieElements"
          sceneClass="genie__canvas"
          :background="getCanvasBackground"
          animationTimeline="genieTimeline"
          :scale="sceneScale"
          :passedStyle="genieStyleObject"
        )

  div.genie-button__list
    BaseButton(
      :class="{'is-disabled': hasNoTransition}"
      :is-disabled="hasNoTransition"
      :is-canvas="true"
      is-hollow=true
      @click="randomizeDirection"
    ) Randomize
    BaseButton.btn-apply(
      :is-canvas="true"
      is-primary=true
      @click="checkAnimationExist"
    ) Apply

  BaseDialog(
    v-if="showWarning"
    is-show=true
    is-alert=true
    text="By applying this, all previous animation will be overwritten."
    button1Text="Overwrite"
    width="500px"
    @confirm="applyAnimation"
    @closeOverlay="closeWarning"
  )
</template>

<script>
import Vue from 'vue';
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import { Linear, TimelineLite } from 'gsap/TweenMax';
import cloneDeep from 'lodash.clonedeep';
import { windowWidth } from '@/assets/scripts/variables';

import CanvasScene from '@/components/ProjectCanvas/CanvasContainer/children/CanvasScene.vue';
import AnimationTabItem from '@/components/ProjectCanvas/AnimationList/children/AnimationTabItem.vue';

export default {
  name: 'GenieAnimator',
  data() {
    return {
      isDirectionShow: false,
      tempValue: {
        value: '',
        direction: '',
      },
      directionList: [],
      genieElements: [],
      timelineBar: new TimelineLite(), // this is for when reach the end
      isShow: true,
      selectedTransition: {},
      isLoading: true,
      minHeight: 640,
      minWidth: 520,
      minHeightSmall: 390,
      showWarning: false,
    };
  },
  components: {
    CanvasScene,
    AnimationTabItem,
  },
  computed: {
    ...mapState(['animationList', 'showGenieModal', 'isGeniePlaying', 'currentGenieTime']),
    ...mapState('canvasElements', ['genieTimeline']),
    ...mapGetters(['getAnimationListDirection']),
    ...mapGetters('canvasElements', [
      'getCanvasSize',
      'getCanvasElements',
      'getCanvasBackground',
      'getCurrentSceneDuration',
    ]),
    playTitle() {
      return this.isGeniePlaying ? 'Pause' : 'Play';
    },
    genieStyleObject() {
      const styleObject = {};

      const { width } = this.getCanvasSize;
      const { height } = this.getCanvasSize;
      const newWidth = this.sceneScale * width;
      const newHeight = this.sceneScale * height;

      styleObject.marginLeft = `${(newWidth / 2) * -1}px`;
      styleObject.marginTop = `${(this.minHeight - newHeight) / 2}px`;

      return styleObject;
    },
    sceneScale() {
      let sceneScale = this.minHeight / this.getCanvasSize.height;

      console.log(
        'sceneScale',
        this.minHeight,
        this.getCanvasSize.height,
        this.minWidth,
        this.getCanvasSize.width,
      );

      if (this.getCanvasSize.width * sceneScale >= this.minWidth) {
        sceneScale = this.minWidth / this.getCanvasSize.width;
      }

      return sceneScale;
    },
    hasNoTransition() {
      return !this.selectedTransition.value || this.selectedTransition.value === 'none';
    },
  },
  methods: {
    ...mapMutations(['setIsShowGenieModal', 'setIsGeniePlaying', 'setCurrentGenieTime']),
    ...mapMutations('canvasElements', ['updateCanvasElementTimelineSettings']),
    ...mapActions('canvasElements', ['applyGenieAnimation']),
    closeGenieAnimator() {
      this.setIsShowGenieModal(false);
    },
    randomizeDirection() {
      this.changeAnimation(this.selectedTransition);
    },
    selectAnimation(transition) {
      // console.log('selectAnimation')
      this.selectedTransition = transition;
      this.changeAnimation(transition);
    },
    changeAnimation(transition) {
      this.setIsGeniePlaying(false);
      this.setTimelineControllerTimeline();

      const { value, directionList } = transition;

      this.tempValue.value = value;

      const maxDirection = directionList.length;
      // so that each layer has at least 2 secs on canvas
      // const minDuration = (this.genieElements.length - 1) * 0.2 + 2;
      const offset = 0.2;
      const duration = this.getCurrentSceneDuration;

      this.genieElements.forEach((element, index) => {
        // console.log('genieElements', element.data.id)
        const rand = Math.floor(Math.random() * maxDirection);

        let direction = '';

        if (directionList[rand]) direction = directionList[rand];

        /* eslint-disable */
        element.time_in = offset * index;
        element.time_out = duration;

        if (element.type === 'videos') {
          const layerMaxDuration = element.max_duration;
          const layerDuration = duration - element.time_in;
          const maxDuration = Math.min(layerMaxDuration, layerDuration);

          element.data.time_start = 0;
          element.data.time_out = maxDuration;

          // for video that can't be looped, set the time_out to be the maximum it can have
          if (element.animation_type === 'none') {
            element.time_out = element.time_in + maxDuration;
          }
        }
        /* eslint-enable */

        Vue.set(element, 'timeline_settings', {
          animateInValue: value,
          animateInDirection: direction,
          animateInDuration: 1,
          animateInOption: 'default',

          animateDuringDirection: '',
          animateDuringValue: '',

          animateOutValue: '',
          animateOutDirection: '',
          animateOutDuration: 1,
          animateOutStart: element.time_out - 1,
          animateOutOption: 'default',

          animationDuration: element.time_out - element.time_in,
          startingTime: element.time_in,
        });
        // console.log('element.timeline_settings.animateInDirection', value, element.timeline_settings.animateInDirection, direction)
      });

      this.$root.$emit('genie-element-animation-updated');

      // make sure everything is loaded before play the genie
      setTimeout(() => {
        this.playGenie();
      }, 200);
    },
    playGenie() {
      // playGenie will always play from beginning
      let currentAnimationTime = this.currentGenieTime;
      if (currentAnimationTime < 0.1) currentAnimationTime = 0.05;
      this.genieTimeline.seek(currentAnimationTime);
      this.timelineBar.seek(currentAnimationTime);

      if (this.isGeniePlaying) {
        // console.log('pause')
        this.setIsGeniePlaying(false);
        this.genieTimeline.pause();
        this.timelineBar.pause();
      } else {
        // console.log('playing')
        this.setIsGeniePlaying(true);
        this.genieTimeline.play();
        this.timelineBar.play();
      }
      console.log('===== PLAY GENIE =====');
    },
    setTimelineControllerTimeline() {
      const timeObj = { time: 0 };
      const totalDuration = this.getCurrentSceneDuration;
      // console.log('totalDuration', this.getCurrentSceneDuration);
      this.setCurrentGenieTime(0);

      this.genieTimeline.clear();
      this.genieTimeline.restart();
      this.timelineBar.clear();
      this.timelineBar.restart();

      const updateTime = () => {
        this.setCurrentGenieTime(parseFloat(timeObj.time.toFixed(1)));
      };

      const completeTime = () => {
        this.setCurrentGenieTime(totalDuration / 2);
        this.genieTimeline.pause();
        this.genieTimeline.seek(this.currentGenieTime);
        this.timelineBar.pause();
        this.timelineBar.seek(this.currentGenieTime);
        this.setIsGeniePlaying(false);
        // console.log('completeTime', this.currentGenieTime)
      };

      this.genieTimeline.addLabel('Start', 0);
      this.timelineBar.addLabel('Start', 0);
      this.timelineBar.to(
        timeObj,
        totalDuration,
        {
          time: `+=${totalDuration}`,
          onUpdate: updateTime,
          onComplete: completeTime,
          ease: Linear.easeNone,
        },
        'Start',
      );
      this.genieTimeline.pause();
      this.genieTimeline.seek(this.currentGenieTime);
      this.timelineBar.pause();
      this.timelineBar.seek(this.currentGenieTime);
    },
    applyAnimation() {
      this.setIsGeniePlaying(false);
      this.setIsShowGenieModal(false);

      this.applyGenieAnimation(this.genieElements);

      setTimeout(() => {
        // make sure the timeline stuff is already applied
        this.$root.$emit('canvas-container-reset-timeline');
        this.$root.$emit('force-reload-timeline');
      }, 500);
    },
    checkAnimationExist() {
      let hasAnimation = false;
      const elementLength = this.getCanvasElements.length;

      // eslint-disable-next-line
      for (let i = 0; i < elementLength; i++) {
        const element = this.getCanvasElements[i];

        if (
          element.timeline_settings.animateInValue !== 'none'
          || ''
          || element.timeline_settings.animateDuringValue !== 'none'
          || ''
          || element.timeline_settings.animateOutValue !== 'none'
          || ''
        ) {
          hasAnimation = true;
          break;
        }
      }

      if (hasAnimation) {
        this.showWarning = true;
        return;
      }

      this.applyAnimation();
    },
    closeWarning() {
      this.showWarning = false;
    },
  },
  beforeMount() {
    // use cloneDeep or else it will affect the actual canvasElements
    this.genieElements = cloneDeep(this.getCanvasElements).reverse();

    if (window.screen.width <= windowWidth.smallLaptopSize) {
      this.minHeight = this.minHeightSmall;
    }
  },
  mounted() {
    this.setTimelineControllerTimeline();
    setTimeout(() => {
      this.isLoading = false;
      const modalHeight = document.getElementsByClassName('genie__container')[0].offsetHeight;
      // eslint-disable-next-line
      if (modalHeight && this.minHeight > modalHeight) this.minHeight = parseInt(modalHeight - 50);
    }, 1500);
    console.log(this.animationList[0]);
  },
};
</script>

<style lang="scss">
.genie-modal {
  .genie-header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding-left: 10px;

    .animation-icon {
      margin-right: 12px;
      transform: rotateY(180deg);
      margin-left: 0;
    }

    .header-title {
      font-weight: 500;
      font-size: 1.125rem;
      margin: 0;
      color: $light;
    }

    .btn-play-genie {
      position: relative;
      color: $blue;
      background: transparent;
      width: 40px;
      height: 40px;
      font-size: 2.5rem;
      margin-left: 10px;
      padding: 0;

      &:hover {
        color: $btnPrimaryHover;
      }

      .icon {
        z-index: 3;
        position: relative;
      }

      // to add white background in play/pause button with smaller size than it's icon
      .icon-white-background {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 19px;
        height: 19px;
        background: $light;
        border-radius: 50%;
      }
    }
  }

  .btn-apply {
    min-width: 140px;
  }

  .content {
    overflow: hidden;
    padding-left: 70px;

    @include small-height-desktop {
      overflow: auto; // cover cannot reach apply button issue at small desktop screens
      margin-right: 5px;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($darkGrey600, 0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $darkGrey800;
    }
  }

  .canvas-modal__content {
    background: $darkGrey700;
    width: 1000px;
  }

  .canvas-modal__closebtn {
    color: $light;
    top: 30px;
    right: 30px;
  }

  .scenes__canvas {
    transform-origin: center center;
  }
}

.genie__preloader {
  background: $darkGrey700;
  position: absolute;
  left: 0;
  top: -30px;
  width: 100%;
  height: calc(100% + 150px);
  z-index: 3;
  transition: opacity 0.25s ease-in-out;

  .preloader__image {
    top: -50px;
  }
}

.genie__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  max-width: 350px;

  .genie__list-item {
    width: 33%;
    padding: 4px;
    list-style: none;

    .btn__circle {
      width: 200px !important;
    }

    .animation-tab__item {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  .animation-tab__item {
    height: 85px;
  }
}

.genie-button__list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
  width: 34%;

  .btn {
    width: 48%;

    &:first-of-type {
      &:not(.is-disabled) {
        border-color: $light;
        color: $light;

        &:hover {
          color: $darkGrey;
        }
      }
    }

    &:last-of-type {
      background: $blue2;

      &:hover {
        background: $light;
      }
    }

    span {
      font-size: 0.8125rem;
    }
  }
}

.genie__container {
  display: flex;
  justify-content: space-between;
  height: 465px;
}

.genie__column {
  overflow: auto;
  overflow-x: hidden;

  &:first-of-type {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &:last-of-type {
    width: 65%;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
}

.genie__wrapper {
  height: 425px;
  overflow: visible;
  width: 100%;
  position: relative;

  @include smallest {
    height: 390px;
    margin: 0;
  }
}

.genie__canvas {
  background: $light;
  position: relative;
  display: inline-block;
  user-select: none;
  overflow: hidden;
  transform-origin: top left;
  left: 50%;
  box-shadow: 0px 26px 26px rgba(10, 31, 68, 0.12), 0px 0px 1px rgba(10, 31, 68, 0.1);
  // transition: opacity 0.25s ease-in-out;

  &.is-hidden {
    opacity: 0;
  }

  & * {
    pointer-events: none !important;
  }
}
</style>
